export enum UserRoles {
  admin = 'admin',
}

export enum RoleFeatures {}

export const rolesConfig: Record<RoleFeatures, UserRoles[]> = {};

export const userRoleMap = {
  user: 'User',
  [UserRoles.admin]: 'Admin',
};
